/* eslint-disable */
import React, { useRef, useState, useEffect } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  Platform,
  Animated,
  Image,
  TouchableWithoutFeedback,
  useColorScheme,
  FlatList,
  TouchableOpacity,
  TextInput,
  KeyboardAvoidingView,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Excellence from "./Images/Excellence.js";
import SelectionIcon from "./Images/SelectionIcon.js";
import SelectedIndex from "./Images/SelectedIndex.js";
import DeviceInfo from "react-native-device-info";
import CameraIcon from "./Images/CameraIcon.js";
import PushNotiifcationIcon from "./Images/pushNotification.js";
import * as firebase from "firebase/app";
import * as messaging from "firebase/messaging";
import * as analytics from "firebase/analytics";
import { getMessaging, onMessage } from "firebase/messaging";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { Camera } from "expo-camera";
import {
  useFonts,
  Poppins_400Regular,
  Poppins_500Medium,
  Poppins_600SemiBold,
  Poppins_700Bold,
  Poppins_900Black,
} from "@expo-google-fonts/poppins";
import {
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
} from "@expo-google-fonts/roboto";

export default function App() {
  const [issueID, setIssueID] = useState();
  const [updateTokenUrl, setUpdateTokenUrl] = useState();
  const [updateToken, setUpdateToken] = useState();
  const [receivedToken, setReceivedToken] = useState(null);
  const [modalVisible, setModalVisible] = useState(true);
  const [issueScreen, setIssueScreen] = useState(false);
  const [thankyouScreen, setThankyouScreen] = useState(false);
  const [wasRotated, setwasRotated] = useState(false);
  const [flatlistData, setflatlistData] = useState([]);
  const [checkedin, setcheckedin] = useState(false);
  const [title, setTitle] = useState();
  const [subTitle, setSubTitle] = useState();
  const [isTokenFound, setTokenFound] = useState(null);
  let issues = [];
  let issuesSelected = [];
  const [showRequestPermission, setShowRequestPermission] = useState(true);
  const [text, onChangeText] = React.useState("Useless Text");
  const [number, onChangeNumber] = React.useState(null);
  const [disabled, setDisabled] = useState(true);
  const [extraCard, setExtraCard] = useState(false);
  const [extraitem, setExtraItem] = useState("");
  const [flatlistItem, setFlatListItem] = useState();
  const [customList, setCustomList] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [showImage, setshowImage] = useState(true);
  const [showSelectedImage, setshowSelectedImage] = useState(false);
  const [deviceID, setDeviceID] = useState();
  const [isloading, setisloading] = useState(false);
  const [starterLoading, setStarterLoading] = useState(false);
  const [buttoninstance, setButtonInstance] = useState();
  const [otherValue, setOtherValue] = useState(false);
  const [ticketDrawScreen, setTicketDrawScreen] = useState(false);
  const [buttonPrototypeID, setButtonPrototypeID] = useState();
  const [buildingId, setBuildingId] = useState();
  const [urlDomainValue, setUrlDomainValue] = useState("");
  const [clientidvalue, setclientidvalue] = useState();
  const [scanSource, setScanSource] = useState();
  const [cameraOpen, setCameraOpen] = useState(false);
  const [taking, setTaking] = useState(false);
  const ref: any = useRef(null);
  // const [permission, requestPermission] = Camera.useCameraPermissions();
  const [hasPermission, setHasPermission] = useState(false);
  const [cameraReady, setCameraReady] = useState(false);
  const [image, setImage] = useState("");

  // if (!permission?.granted && permission?.canAskAgain) {
  //   Camera.requestCameraPermissionsAsync().then((result) => setHasPermission(result.granted))
  // }

  const { width } = useWindowDimensions();
  const height = Math.round((width * 16) / 9);

  let buttonInstanceID;
  let urlDomain = null;
  let domainValue = null;
  let clientIdHeaderValue = null;
  let reportIssueURL;
  let finalURL;
  let flatlistvalue = flatlistData.map((v, i) => ({
    key: i,
    isSelect: false,
    v,
  }));
  let itemSelected = [];
  let issueSelectedId;

  const firebaseConfig = {
    apiKey: "AIzaSyD5QYRRqEysOZibzYPmOVj21OTQ6Lp9wus",
    authDomain: "hereworks-happy.firebaseapp.com",
    projectId: "hereworks-happy",
    storageBucket: "hereworks-happy.appspot.com",
    messagingSenderId: "225345105784",
    appId: "1:225345105784:web:f13e526e6ca49bf895628d",
    measurementId: "G-D8ZDKGM8LZ",
  };

  firebase.initializeApp(firebaseConfig);

  if (!firebase.getApps().length) {
    console.log("initializing the app...");
    firebase.initializeApp(firebaseConfig);
  } else {
    console.log("app already inititalzed");
    firebase.getApp(); // if already initialized, use that one
  }

  let msg = messaging.getMessaging();

  messaging.onMessage(msg, function (payload) {
    console.log("onMessage::", JSON.stringify(payload));

    const notificationTitle = payload.data.title;

    console.log("notificationTitle", notificationTitle);
    const notificationOptions = {
      body: payload.data.body,
    };
    console.log("notificationOptions", notificationOptions);

    navigator.serviceWorker
      .getRegistration("./firebase-cloud-messaging-push-scope")
      .then(function (registration) {
        setTimeout(() => {
          registration.showNotification(notificationTitle, notificationOptions);
          registration.update();
        }, 100);
      })
      .catch(function (err) {
        console.log("No Service Worker Registered", err);
      });
  });

  useEffect(() => {
    urlDomain = window.location.hostname;
    // urlDomain = 'hereworks.tech';
    if (urlDomain === "hereworks.tech") {
      console.log("url domain is", urlDomain);
      domainValue = "api-v2.hereworks.tech";
      setUrlDomainValue("api-v2.hereworks.tech");
      setclientidvalue("hereworks");
      setUpdateTokenUrl(domainValue);
      clientIdHeaderValue = "hereworks";
      const initialURL = window.location.pathname;
      console.log("initialUrl", initialURL);
      const arr = initialURL.split("/");
      console.log("final button instance value", arr[1]);
      // buttonInstanceID = 'Afv355765y';
      buttonInstanceID = arr[1];
      setButtonPrototypeID(buttonInstanceID);

      const scanvalue = window.location.search;
      console.log("scanvalue", scanvalue);

      const ssvalue = scanvalue.split("?ss=");
      console.log("ssvallue in reportissue", ssvalue[1]);
      setScanSource(ssvalue[1]);

      setIssueScreen(true);
      setThankyouScreen(false);
      issueList();
      setFlatListItem(flatlistvalue);
      //
    }
    if (urlDomain === "dev.hereworks.tech") {
      console.log("url domain is", urlDomain);
      domainValue = "api-v2-dev.hereworks.tech";
      setUpdateTokenUrl(domainValue);
      setUrlDomainValue("api-v2-dev.hereworks.tech");
      setclientidvalue("devhereworks");
      clientIdHeaderValue = "devhereworks";
      const initialURL = window.location.pathname;
      console.log("initialUrl", initialURL);
      const arr = initialURL.split("/");
      console.log("final button instance value", arr[1]);
      buttonInstanceID = arr[1];
      // buttonInstanceID = '555222';
      setButtonPrototypeID(buttonInstanceID);

      const scanvalue = window.location.search;
      console.log("scanvalue", scanvalue);

      const ssvalue = scanvalue.split("?ss=");
      console.log("ssvallue in reportissue", ssvalue[1]);
      setScanSource(ssvalue[1]);
      //setButtonPrototypeID(ssvalue[0])
      //buttonInstanceID = ssvalue[0]
      //  setScanSource(ssvalue[1])

      setIssueScreen(true);
      setThankyouScreen(false);
      issueList();
      setFlatListItem(flatlistvalue);
      //    setButtonPrototypeID(buttonInstanceID)
    }

    if (urlDomain === "demo.hereworks.tech") {
      console.log("url domain is", urlDomain);
      domainValue = "api.demo.hereworks.tech";
      setUpdateTokenUrl(domainValue);
      setUrlDomainValue("api.demo.hereworks.tech");
      setclientidvalue("demohereworks");
      clientIdHeaderValue = "demohereworks";
      const initialURL = window.location.pathname;
      console.log("initialUrl", initialURL);
      const arr = initialURL.split("/");
      console.log("final button instance value", arr[1]);
      buttonInstanceID = arr[1];
      setButtonPrototypeID(buttonInstanceID);

      const scanvalue = window.location.search;
      console.log("scanvalue", scanvalue);

      const ssvalue = scanvalue.split("?ss=");
      console.log("ssvallue in reportissue", ssvalue[1]);
      setScanSource(ssvalue[1]);
      //setButtonPrototypeID(ssvalue[0])
      //buttonInstanceID = ssvalue[0]
      //  setScanSource(ssvalue[1])

      setIssueScreen(true);
      setThankyouScreen(false);
      issueList();
      setFlatListItem(flatlistvalue);
      //    setButtonPrototypeID(buttonInstanceID)
    }
  }, []);

  const showExtraCard = (index) => {
    if (extraCard === true && customList?.isSelect === false) {
      const newItem: any = flatlistItem.map((value: any) => {
        if (value.key === index) {
          //  return {...value, isSelect:!value.isSelect}
          setSelectedId(null);
          return { ...value, isSelect: false };
        } else {
          //  return value
          return { ...value, isSelect: false };
        }
      });
      console.log('coming in true');
      setExtraCard(false);
      setFlatListItem(newItem);
      for (let i = 0; i < newItem.length; i++) {
        const newArray = newItem[i].isSelect;
        console.log('new Array', newArray);
        itemSelected.push(newArray);
      }
      //console.log("issues array" ,issuesSelected );

      console.log('item selected array', itemSelected);
      if (itemSelected.includes(true)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  const issueList = async () => {
    //getToken(isTokenFound);
    setStarterLoading(true);
    const deviceId = DeviceInfo.getUniqueId();
    console.log("deviceid", deviceId);
    setDeviceID(deviceId);
    console.log("urlDomainValue", domainValue, urlDomainValue);

    const tokenValue = await AsyncStorage.getItem("pntToken");
    console.log("tokenValue in issueList", tokenValue);
    if (tokenValue !== null) {
      setTokenFound(tokenValue);
    }
    console.log("coming in issueList");
    console.log(
      "final URL",
      "https://" + domainValue + "/assets/" + buttonInstanceID
    );

    finalURL =
      "https://" + domainValue + "/assets/" + buttonInstanceID;
    console.log("FINALURL", finalURL);
    console.log("clientidvalue in issueList", clientIdHeaderValue);

    fetch(finalURL, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        clientid: clientIdHeaderValue,
        deviceuuid: deviceId,
        accesskey: "b7d3137e8e7cfb37e4607962fb7b153a",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response in issueList", response);
        if (response.isError === true) {
          setStarterLoading(false);
          alert("Something went wrong.Please try again later");
        }
        const buttonInstance = response.buttoninstance;
        console.log("buttonInstance", buttonInstance);

        const issueTitle = buttonInstance.title;

        const titleEdit = issueTitle.replace('{"\\n"}', "\n");

        const issueSubTitle = buttonInstance.subtitle;
        const subTitleEdit = issueSubTitle.replace('{"\\n"}', "\n");

        console.log("titles", issueTitle, subTitleEdit);
        setBuildingId(buttonInstance.buildingid);
        setTitle(titleEdit);
        setSubTitle(issueSubTitle);

        const client_id = buttonInstance.clientid;
        console.log("client_id", client_id);
        if (client_id !== null) {
          setclientidvalue(client_id);
        }

        issues = buttonInstance.issues;
        console.log("issues array", issues);
        console.log("flatlistdata", flatlistData);
        flatlistvalue = issues.map((v, i) => ({
          key: i,
          isSelect: false,
          value: v.Issue,
          id: v.Id,
          enabled: v.Enabled,
        }));
        console.log("flatlistvalue", flatlistvalue);

        setFlatListItem(flatlistvalue);
        setStarterLoading(false);
      });
  };

  const issueReported = async () => {
    analytics.logEvent(analytics.getAnalytics(), 'Report an Issue');
    for (let i = 0; i < flatlistItem.length; i++) {
      const selectedValue = flatlistItem[i];
      console.log("selected value", selectedValue);
      if (selectedValue.isSelect === true) {
        console.log("value is true , adding to arrray", selectedValue.value);
        if (selectedValue.value !== "Other") {
          issuesSelected.push(selectedValue.value);
          issueSelectedId = selectedValue.id;
        } else if (number !== null) {
          if (!number.trim()) {
            alert("Cannot submit empty issue");
            setisloading(false);
          } else {
            issuesSelected.push(number);
            issueSelectedId = selectedValue.id;
          }
        } else {
          alert("Cannot submit empty issue");
          setisloading(false);
        }
      }
    }
    console.log("issue selected array", issuesSelected);
    setisloading(true);
    console.log("token in issue reported", isTokenFound);
    console.log("button buttonPrototypeID", buttonPrototypeID);
    console.log("clientidvalue in issueReported", clientidvalue);
    console.log("scan source value in issuereported", scanSource);

    //  setIssueScreen(false)
    //  const fcmToken = await AsyncStorage.getItem('firebasetoken')
    //  const username = await AsyncStorage.getItem('username')
    // const emailValue = await AsyncStorage.getItem('email')
    //  console.log("fcm token in ReportIssue" , fcmToken);
    //    console.log("username in ReportIssue" , username);
    //      console.log("email in ReportIssue" , emailValue);
    reportIssueURL = "https://" + urlDomainValue + "/assets/issues";
    console.log("reportIssueURL", reportIssueURL);

    fetch(reportIssueURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        clientid: clientidvalue,
        accesskey: "b7d3137e8e7cfb37e4607962fb7b153a",
      },
      body: JSON.stringify({
        bid: buttonPrototypeID,
        deviceid: deviceID,
        issues: issuesSelected,
        pnt: isTokenFound,
        pnttype: "firebase",
        email: null,
        name: null,
        scansrc: scanSource,
        issueprototypeid: issueSelectedId,
        buildingid: buildingId,
        image,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response from submit issue api", response);

        const submitMsg = response.msg;

        const issueIdValue = response.issueid;
        console.log("issueIdValue", issueIdValue);
        setIssueID(issueIdValue);

        if (submitMsg) {
          if (isTokenFound !== null) {
            console.log("get token is not actually undefined)");
            setShowRequestPermission(false);
          }
          setisloading(false);
          setThankyouScreen(true);
          setIssueScreen(false);
        } else {
          setisloading(false);
          alert("something is wrong , try again");
        }
      });
  };

  const getIndex = (index, item) => {
    analytics.logEvent(analytics.getAnalytics(), 'Selected issue:', ['index', index]);

    const newItem = flatlistItem.map((value) => {
      if (value.key === index) {
        if (value.isSelect) {
          setSelectedId(null);
        } else {
          setSelectedId(index);
        }
        return { ...value, isSelect: !value.isSelect };
      } else {
        return { ...value, isSelect: false };
      }
    });

    setFlatListItem(newItem);

    for (let i = 0; i < newItem.length; i++) {
      const newArray = newItem[i].isSelect;
      console.log("new Array", newArray);
      itemSelected.push(newArray);
    }
    console.log("item selected array", itemSelected);
    if (itemSelected.includes(true)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    const itemValue = item;
    const indexValue = index;
    console.log("index in getIndex", indexValue, itemValue);

    if (itemValue.value === "Other") {
      setOtherValue(true);
    }
    if (itemValue.value === "Other" && itemValue.isSelect === false) {
      console.log("coming in if");
      setExtraCard(true);
      setExtraItem("Other");
      setCustomList(itemValue);
    } else {
      console.log("coming in else");
      setExtraCard(false);
    }

    if (indexValue !== undefined) {
      // setSelectedId(indexValue);
      setshowSelectedImage(true);
      setshowImage(false);
    }
  };

  const notificationPermission = () => {
    analytics.logEvent(analytics.getAnalytics(), 'Allow notification');
    let msg = messaging.getMessaging();
    messaging
      .getToken(msg)
      .then((token) => {
        console.log("have permission");
        setTokenFound(token);
        AsyncStorage.setItem("pntToken", token);
        sendPushNotificationToken(token).then(() => {
          setShowRequestPermission(false);
        });
      })
      .catch((err) => {
        console.log("currentsession error:", err);
      });
    //
  };

  const sendPushNotificationToken = async (deviceToken) => {
    const updateUrl =
      "https://" + updateTokenUrl + "/button/reporterapp/updatetoken";
    console.log("update url", updateUrl);
    console.log("deviceID in sendPushNotificationToken", deviceToken);
    const deviceTokenData = deviceToken;
    console.log("issue id in sendPushNotificationToken", issueID);

    fetch(updateUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        clientid: clientidvalue,
        accesskey: "b7d3137e8e7cfb37e4607962fb7b153a",
      },
      body: JSON.stringify({
        issueid: issueID,
        bid: buttonPrototypeID,
        pnt: deviceTokenData,
        pnttype: "firebase",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("hitting the url");
        console.log("repsosne", response);
        const submitMsg = response.msg;

        if (submitMsg !== undefined) {
          setShowRequestPermission(false);
        }
      })
      .catch((err) => {
        console.log("currentsession error:", err);
      });
  };

  const nothanksbutton = () => {
    analytics.logEvent(analytics.getAnalytics(), 'No Thanks');
    setShowRequestPermission(false);
  };

  const takePhotoButton = () => (
    <View style={{ opacity: disabled ? 0.3 : 1 }}>
      <TouchableOpacity
        style={{
          borderColor: "#2173C4",
          borderWidth: 1,
          backgroundColor: "#fff",
          height: 54,
          borderRadius: 12,
          width: wp("85%"),
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 12,
          flexDirection: "row",
        }}
        onPress={() => {
          analytics.logEvent(analytics.getAnalytics(), 'Take a photo');
          setCameraOpen(true);
        }}
        disabled={disabled}
      >
        <CameraIcon />
        <Text
          allowFontScaling={false}
          style={{
            color: "#2173C4",
            fontSize: 16,
            marginLeft: 12,
            fontWeight: "500",
          }}
        >
          Take a photo
        </Text>
      </TouchableOpacity>
    </View>
  );

  const [fontsLoaded] = useFonts({
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_700Bold,
    Poppins_900Black,
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_500Medium,
    Roboto_700Bold,
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <View style={styles.scrollView}>
      {issueScreen && (
        <View style={styles.container}>
          <View style={styles.headstyle}>
            <Text
              style={{
                fontWeight: "600",
                fontFamily: "Poppins_600SemiBold",
                paddingTop: 0,
                fontSize: 24,
                color: "#323E48",
                textAlign: "center",
                marginHorizontal: hp("6%"),
              }}
            >
              {title}
            </Text>
            <Text
              style={{
                fontWeight: "500",
                fontFamily: "Poppins_500Medium",
                fontSize: 16,
                color: "#696D70",
                textAlign: "center",
                marginHorizontal: hp("5%"),
              }}
            >
              {subTitle}
            </Text>
          </View>
          <ScrollView
            contentContainerStyle={{ flex: 1, alignItems: "center" }}
            style={{ flex: 1, marginBottom: 160, alignSelf: 'stretch', paddingHorizontal: 24 }}
          >
            <KeyboardAvoidingView behavior="height" keyboardVerticalOffset={0} style={{ flex: 1, alignSelf: 'stretch' }}>
              <View style={{ flex: 1, marginTop: hp("-1%"), height: 50 }}>
                {!extraCard && <FlatList
                  data={flatlistItem} //just for demo
                  keyExtractor={(item) => item.key}
                  extraData={selectedId}
                  renderItem={({ index, item }) => {
                    if (selectedId !== null && !item.isSelect) {
                      return null;
                    } else if (!item.enabled) {
                      return null;
                    }
                    return (
                      <TouchableOpacity onPress={() => getIndex(index, item)}>
                        <View style={styles.card}>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginLeft: hp("1%"),
                              justifyContent: "space-between",
                            }}
                          >
                            {item.isSelect ? (
                              <SelectedIndex />
                            ) : (
                              <SelectionIcon />
                            )}

                            <Text
                              allowFontScaling={false}
                              style={{
                                fontFamily: "Poppins_500Medium",
                                fontSize: 16,
                                color: "#000000",
                                fontWeight: "500",
                                marginLeft: hp("1%"),
                                flex: 1,
                              }}
                            >
                              {item.value}
                            </Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    );
                  }}
                />}
                <TouchableOpacity onPress={() => showExtraCard(selectedId)}>
                {extraCard && (
                  <View style={styles.extracard}>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: hp("1%"),
                        justifyContent: "space-between",
                        marginVertical: hp("1%"),
                      }}
                    >
                      {otherValue === true ? (
                        <SelectedIndex />
                      ) : (
                        <SelectionIcon />
                      )}

                      <Text
                        allowFontScaling={false}
                        style={{
                          fontFamily: "Poppins_500Medium",
                          fontSize: 16,
                          color: "#000000",
                          fontWeight: "500",
                          marginLeft: hp("1%"),
                        }}
                      >
                        {extraitem}
                      </Text>
                    </View>
                    <TouchableWithoutFeedback>
                      <TextInput
                        style={styles.input}
                        onChangeText={onChangeNumber}
                        value={number}
                        placeholder="Type your issue here.."
                        keyboardType="default"
                        multiline={true}
                        numberOfLines={10}
                      />
                    </TouchableWithoutFeedback>
                  </View>
                )}
                </TouchableOpacity>
              </View>
            </KeyboardAvoidingView>
            {image !== "" && (
              <View
                style={{
                  flex: 1,
                  width: wp("83%"),
                  marginTop: 24,
                  height: 150,
                  marginHorizontal: wp("8%"),
                }}
              >
                <Image
                  key={Date.now()}
                  source={{
                    uri: image,
                  }}
                  resizeMode={"cover"}
                  style={{ flex: 1, borderRadius: 10 }}
                />
                <TouchableOpacity
                  style={{
                    width: 36,
                    height: 36,
                    borderRadius: 18,
                    position: "absolute",
                    right: 10,
                    top: 10,
                    backgroundColor: "#E6EEF7",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onPress={() => {
                    analytics.logEvent(analytics.getAnalytics(), 'Remove Image');
                    setImage("");
                  }}
                >
                  <Text>X</Text>
                </TouchableOpacity>
              </View>
            )}
          </ScrollView>
          {isloading && (
            <View style={styles.spinnerView}>
              <ActivityIndicator size="large" />
            </View>
          )}
          {starterLoading && (
            <View style={styles.spinnerView}>
              <ActivityIndicator size="large" />
            </View>
          )}
          {isloading && (
            <View style={styles.spinnerView}>
              <ActivityIndicator size="large" />
            </View>
          )}
          <View style={{ position: "absolute", bottom: 0, padding: 10 }}>
            {!disabled && (
              <View style={{}}>
                {takePhotoButton()}
                <TouchableOpacity onPress={() => issueReported()}>
                  <View style={styles.ReportIssueButton}>
                    <Text
                      allowFontScaling={false}
                      style={{
                        fontFamily: "Poppins_500Medium",
                        fontSize: 16,
                        color: "#FFFFFF",
                        fontWeight: "500",
                      }}
                    >
                      Report an Issue
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            )}
            {disabled && (
              <View style={{}}>
                <TouchableOpacity>
                  <View style={styles.ReportIssueButtonFaded}>
                    <Text
                      allowFontScaling={false}
                      style={{
                        fontFamily: "Poppins_500Medium",
                        fontSize: 16,
                        color: "#FFFFFF",
                        fontWeight: "500",
                      }}
                    >
                      Report an Issue
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      )}
      {thankyouScreen && (
        <View
          style={{
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: "#E5ECF0",
            flex: 1,
          }}
        >
          <View style={{ paddingTop: wp("13%") }}>
            <Excellence />
          </View>
          <View
            style={{
              alignItems: "center",
              flexDirection: "column",
              paddingTop: 20,
              marginHorizontal: 24,
            }}
          >
            <Text
              allowFontScaling={false}
              style={{
                fontWeight: "600",
                fontFamily: "Poppins_600SemiBold",
                fontSize: 24,
                color: "#323E48",
                marginBottom: hp("1%"),
                textAlign: 'center',
              }}
            >
              Someone's coming to resolve your report
            </Text>
            <Text
              allowFontScaling={false}
              style={{
                fontWeight: "600",
                fontFamily: "Poppins_600SemiBold",
                fontSize: 24,
                color: "#323E48",
                marginBottom: hp("1%"),
                textAlign: 'center',
                marginTop: 98,
              }}
            >
              We will notify you once the issue is fixed
            </Text>
            <Text
              allowFontScaling={false}
              style={{
                fontWeight: "500",
                fontFamily: "Poppins_500Medium",
                fontSize: 16,
                color: "#383F44",
                marginBottom: hp("6%"),
                textAlign: 'center',
              }}
            >
              Thank you for reporting!
            </Text>
            <Text
              allowFontScaling={false}
              style={{
                fontWeight: "500",
                fontFamily: "Poppins_500Medium",
                fontSize: 16,
                color: "#383F44",
                marginBottom: hp("7%"),
                textAlign: 'center',
              }}
            >
              Keep reporting and make your workplace a better place.
            </Text>
          </View>

          {showRequestPermission && (
            <View
              style={{
                alignItems: "center",
                flexDirection: "column",
                position: "absolute",
                bottom: wp("0.2%"),
                backgroundColor: "#FFFFFF",
                width: wp("100%"),
                borderRadius: 20,
              }}
            >
              <View style={{ paddingTop: wp("12%") }}>
                <PushNotiifcationIcon />
              </View>

              <View
                style={{
                  alignItems: "center",
                  flexDirection: "column",
                  paddingTop: hp("5%"),
                }}
              >
                <Text
                  allowFontScaling={false}
                  style={{
                    fontWeight: "500",
                    fontFamily: "Poppins_500Medium",
                    fontSize: 18,
                    color: "#000000",
                    textAlign: "center",
                    marginBottom: hp("8%"),
                    marginHorizontal: 24,
                  }}
                >
                  Be the first to know when the issue is fixed!
                </Text>

                <TouchableOpacity onPress={() => notificationPermission()}>
                  <View style={styles.getNotificationPermission}>
                    <Text
                      allowFontScaling={false}
                      style={{
                        fontFamily: "Poppins_500Medium",
                        fontSize: 16,
                        color: "#FFFFFF",
                        fontWeight: "500",
                      }}
                    >
                      Allow Notification
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => nothanksbutton()}>
                  <View style={styles.noThanksButton}>
                    <Text
                      allowFontScaling={false}
                      style={{
                        fontFamily: "Poppins_500Medium",
                        fontSize: 16,
                        color: "#2173C4",
                        fontWeight: "500",
                      }}
                    >
                      No,Thanks
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </View>
      )}
      {cameraOpen && (
        <View
          style={{
            margin: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          {/* {!permission?.granted && !hasPermission && <Text>Camera permission denied</Text>} */}
          <Camera
            ratio="16:9"
            useCamera2Api={true}
            style={{
              height,
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            ref={ref}
            // onCameraReady={() => setCameraReady(true)}
            // onMountError={(error) => Alert.alert(JSON.stringify(error))}
          >
            <TouchableOpacity
              style={{
                borderWidth: 6,
                borderColor: "#fff",
                borderRadius: 35,
                width: 70,
                height: 70,
                backgroundColor: "transparent",
                padding: 8,
                marginBottom: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
              onPress={() => {
                // Haptics.notificationAsync();
                // Haptics.selectionAsync();
                // Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
                analytics.logEvent(analytics.getAnalytics(), 'Camera Shutter');
                setTaking(true);
                ref.current
                  .takePictureAsync({ base64: true })
                  .then((picture) => {
                    console.log("takePicureResponse");
                    console.log(picture.base64);
                    setImage(`${picture.base64}`);
                    setCameraOpen(false);
                    setTaking(false);
                  })
                  .catch((error) => console.log(error));
              }}
            >
              <View
                style={{
                  backgroundColor: "#fff",
                  width: 50,
                  height: 50,
                  borderRadius: 25,
                }}
              />
              {taking && <ActivityIndicator style={{ position: "absolute" }} />}
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // analytics('Close Camera');
                analytics.logEvent(analytics.getAnalytics(), 'Close Camera');
                setCameraOpen(false);
              }}
              style={{
                width: 36,
                height: 36,
                borderRadius: 18,
                position: "absolute",
                right: 24,
                top: 24,
                backgroundColor: "#E6EEF7",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text>X</Text>
            </TouchableOpacity>
          </Camera>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
    height: hp("100%"),
  },
  headstyle: {
    alignItems: "center",
    paddingTop: hp("3%"),
    marginBottom: hp("2%"),
  },
  flatliststyle: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#F2F6F8",
    alignItems: "center",
  },
  logo: {
    width: 300,
    height: 300,
  },
  title: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 16,
  },
  text: {
    color: "#fff",
  },
  link: {
    color: "#1B95E0",
  },
  button: {
    borderRadius: 3,
    padding: 20,
    marginVertical: 10,
    marginTop: 10,
    backgroundColor: "#1B95E0",
  },
  buttonText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 16,
  },
  card: {
    backgroundColor: "#FFF",
    marginVertical: wp("2%"),
    paddingVertical: 10,
    // height: wp('15%'),
    alignItems: "flex-start",
    justifyContent: "center",
    borderRadius: 12,
    alignSelf: 'stretch',
  },
  extracard: {
    backgroundColor: "#FFF",
    paddingVertical: 10,
    height: 160,
    marginVertical: wp("2%"),
    alignItems: "flex-start",
    justifyContent: "center",
    borderRadius: 12,
  },
  input: {
    height: wp("28%"),
    width: wp("78%"),
    borderRadius: 12,
    padding: 12,
    backgroundColor: "#FFFFFF",
    textAlignVertical: "top",
    fontSize: 16,
    marginLeft: 5,
    outlineStyle: "none",
  },
  ReportIssueButton: {
    backgroundColor: "#2173C4",
    height: 54,
    width: wp("85%"),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
  },
  ReportIssueButtonFaded: {
    backgroundColor: "#9FC2E3",
    height: 54,
    width: wp("85%"),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
  },
  spinnerTextStyle: {
    color: "#FFF",
  },
  spinnerView: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F5FCFF88",
  },
  getNotificationPermission: {
    backgroundColor: "#2173C4",
    height: 54,
    width: wp("80%"),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 12,
    marginBottom: hp("2%"),
  },
  modalView: {
    height: wp("30%"),
  },
  noThanksButton: {
    height: 24,
    marginBottom: hp("4%"),
  },
});
